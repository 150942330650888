import request from '@/api/request'
//列表---分页
export function index(params) {
	return request({ url: '/gateway/admin/area/areaList',method: 'get',data: params})
}

export function index1(params) {
	return request({ url: '/gateway/admin/area/areaList',method: 'get',data: params})
}

//删除
export function del(params) {
	return request({ url: '/gateway/admin/area/delArea',method: 'get',data: params})
}

//添加
export function add(params) {
	return request({ url: '/gateway/admin/area/addArea',method: 'post',data: params})
}

//编辑
export function update(params) {
	return request({ url: '/gateway/admin/area/updateArea',method: 'post',data: params})
}


export function areaList(params) {
	return request({ url: '/gateway/admin/area/areaList',method: 'get',data: params})
}


export function regionListByCityCode(params) {
	return request({ url: '/gateway/admin/area/regionListByCityCode',method: 'get',data: params})
}

export function regionListByParentRegionId(params) {
	return request({ url: '/gateway/admin/area/regionListByParentRegionId',method: 'get',data: params})
}

//添加
export function addRegion(params) {
	return request({ url: '/gateway/admin/area/addRegion',method: 'post',data: params})
}

export function delRegion(params) {
	return request({ url: '/gateway/admin/area/delRegion',method: 'get',data: params})
}

//编辑
export function editRegion(params) {
	return request({ url: '/gateway/admin/area/editRegion',method: 'post',data: params})
}


export function changeRegionStatus(params) {
	return request({ url: '/gateway/admin/area/changeRegionStatus',method: 'get',data: params})
}

// 根据城市code获取大区列表-无分页
export function regionAllListByCityCode(params) {
	return request({ url: '/gateway/admin/area/regionAllListByCityCode',method: 'get',data: params})
}


export function disableArea(params) {
	return request({ url: '/gateway/admin/area/disableArea',method: 'get',data: params})
}

// 根据大区id获取小区列表-无分页
export function regionAllListByParentRegionId(params) {
	return request({ url: '/gateway/admin/area/regionAllListByParentRegionId',method: 'get',data: params})
}

export function getRegionListForZj(params) {
	return request({ url: '/gateway/admin/area/getRegionListForZj',method: 'get',data: params})
}

// 根据小区id获取门店列表(regionId传空将获取无区域的门店列表，regionType 0 运营主管区域 1 运营专员区域 2 售后主管区域 3 售后专员区域
export function manageShopListByRegionIdApi(params) {
	return request({ url: '/gateway/admin/area/manageShopListByRegionId',method: 'get',data: params})
}

// 管理员批量修改门店归属区域(同时修改运营区域和售后区域)
export function changeShopAreaForAdminBatchApi(params) {
	return request({ url: '/gateway/walker/work/changeShopAreaForAdminBatch',method: 'post',data: params})
}


