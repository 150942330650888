<template>
	<div class="map">
		<div class="side_fixed">
			<div class="top">
				<img src="../../assets/images/aaa.png" alt="" style="width: 50px;height: 35px;">
				<div><span>中墅地产</span>全国店铺情况</div>
			</div>
			<div class="search">
				<div class="shop_name">
					<el-input type="text" placeholder="请输入门店名称" size="small" v-model="form.shopName"></el-input>
				</div>
				<div class="city_select">
					<el-select style="width: 100%" v-model="form.provinceCode" size="small" placeholder="请选择"
						@change="getProvince">
						<el-option v-for="item in pro_list" :key="item.provinceCode" :label="item.provinceName"
							:value="item.provinceCode"></el-option>
					</el-select>
					<el-select style="width: 100%;margin-left: 5px;" size="small" v-model="form.cityCode"
						placeholder="请选择">
						<el-option v-for="item in city_list" :key="item.cityCode" :label="item.cityName"
							:value="item.cityCode"></el-option>
					</el-select>
				</div>
				<div class="city_select" style="margin-top: 5px;">
					<el-select @change="changeDa" style="width: 100%" v-model="form.regionId1" size="small" placeholder="请选择"
						>
						<el-option v-for="item in quList" :key="item.regionId" :label="item.regionName"
							:value="item.regionId"></el-option>
					</el-select>
					<el-select style="width: 100%;margin-left: 5px;" v-model="form.regionId" size="small" placeholder="请选择"
						>
						<el-option v-for="item in smqu_list" :key="item.regionId" :label="item.regionName"
							:value="item.regionId"></el-option>
					</el-select>
					<!-- <el-button size="small" style="background-color: #094693;color: #fff;margin-left: 5px;" @click="handleSearch">搜索</el-button> -->
				</div>
				<div style="margin-top: 5px;margin-left: 5px;">
					<el-button size="small" style="background-color: #094693;color: #fff;margin-left: 5px;" @click="handleSearch">搜索</el-button>
					<el-button size="small" style="background-color: #fff;color: #999;margin-left: 5px;" @click="handleSearch1">清空</el-button>
				</div>
			</div>
			<div class="h_line"></div>
			<div>
				<div class="bol_ti">门店分布数据</div>
				<ul class="ul">
					<li v-for="(item,index) in shop_sa.shopCityCounts">
						<div>{{item.cityName}}</div> <span>{{item.shopCount}}家</span>
					</li>
					
				</ul>
			</div>
			<div class="h_line"></div>
			<div>
				<div class="bol_ti">门店状态</div>
				<ul class="ul">
					<li>
						<div class="flex"><i style="background-color: #357ADA;"></i>
							<div>正常运营门店：</div>
						</div><span>{{shop_sa.shopStateTo0Count}}家</span>
					</li>
					<li>
						<div class="flex"><i style="background-color: #4FA987;"></i>
							<div>待开业门店：</div>
						</div><span>{{shop_sa.shopStateTo2Count}}家</span>
					</li>
					<li>
						<div class="flex"><i style="background-color: #C4C4C4;"></i>
							<div>已关闭门店：</div>
						</div><span>{{shop_sa.shopStateTo1Count}}家</span>
					</li>
					<li v-if="shop_sa.shopCountBySearch">
						<div class="flex">
							<div>当前筛选区域门店总数：</div>
						</div><span>{{shop_sa.shopCountBySearch}}家</span>
					</li>
				</ul>
			</div>
		</div>
		<!-- 地图 -->
		<div class="map_all">
			<baidu-map :mapClick="false" @ready="mapReady" class="map-detail" :scroll-wheel-zoom="true">
				<bm-marker  v-for="(item, index) of markers" :key="index"
					:icon="{ url: item.icon, size: { width: 71, height:71 } }"
					:position="{ lng: item.longitude, lat: item.latitude }" @click="lookDetail(item)"
					@mouseover="handleMover(item,index)" @mouseout="handleOut(item,index)">
					<bm-label :content="item.shopName"
						:offset="{width: 4,height:2 }" />
					<bm-info-window :show="item.show">
						<div>
							<div class="info_top">	
							     
							    <div class="shop_status" v-if="shopInfo.shopStatus==0">正常运营</div>
								<div class="shop_status" v-if="shopInfo.shopStatus==1" style="background-color: #C4C4C4;">已关闭</div>
								<div class="shop_status" v-if="shopInfo.shopStatus==2" style="background-color: #4FA987;">待开业</div>
								<div class="tu_div">
									<img src="../../assets/images/tu.png" alt="" class="tu_s">
								</div>
								
								<div class="titlse" >{{shopInfo.shopName}}</div>
							    <div class="address">地址：{{shopInfo.address}}</div>
								<div class="faren">法人：{{shopInfo.legalName}}   {{shopInfo.legalPhone}}</div>
								<div class="lin"></div>
								<div class="yun">
									<img v-if="shopInfo.headPortrait" :src="shopInfo.headPortrait" alt="">
									<img v-else src="../../assets/images/head.png" alt="">
									<div>
										<div style="color: #666666;">门店负责人：{{shopInfo.workName}}</div>
										<div style="padding-top: 5px;">{{shopInfo.workerPhone}}</div>
									</div>
								</div> 
							</div>
						</div>
					</bm-info-window>
				</bm-marker>	
			</baidu-map>
		</div>
	</div>
</template>
<script>
	import {index,regionListByCityCode,addRegion,regionAllListByCityCode,} from '@/api/admin/region'
	import {showShopStatistics,showShopSearch,showShop,detail,getRegionId,regionAllListByParentRegionId} from '@/api/admin/map'
	export default {
		name: 'maps',
		components: {},
		data() {
			return {
				smqu_list:[],
                quList:[],
				shows: false,
                shop_sa:{shopCityCounts:[]},
				markers: [],
				pro_list: [],
				city_list: [],
				a:'117.218631',
				b:'31.800013',
				map: null,
				shopInfo:'',
				form: {
					regionId1:'',
					regionId:'',
					provinceCode: '',
					cityCode: '',
					shopName:''
				}
			}
		},

     
       
		methods: {
			// 获取省份列表
			getPri() {
				let that = this;
				index({}).then(res => {
					that.pro_list = res.data;
					// that.form.provinceCode = res.data[0].provinceCode;
					// that.city_list = that.pro_list[0].cityList;
					// that.form.cityCode = res.data[0].cityList[0].cityCode
					// that.getBigReg()
				})
			},
			
			
			//获取大区列表
		    getBigReg(){
				let that = this;
				getRegionId({cityCode:that.form.cityCode,regionType:'0'}).then(res=>{
					that.quList = res.data;
					// that.form.regionId1 = res.data[0].regionId;
					// that.getSmallReg()
				
				})
			},
			
		   //根据大区获取小区列表
		   getSmallReg(){
			   let that = this;
			   regionAllListByParentRegionId({firstRegionId:that.form.regionId1}).then(res=>{
					that.smqu_list = res.data;
					that.form.regionId = res.data[0].regionId;
					// that.getShopList()
			   })
		   },
		   
		   
		   changeDa(e){
			   let that = this;
			   regionAllListByParentRegionId({firstRegionId:e}).then(res=>{
					that.smqu_list = res.data;
					// that.form.regionId = res.data[0].regionId;
					//that.getShopList()
			   })
			  
		   },
			
			//搜索
			handleSearch(){
				let that = this;
				that.getShopList();
				
				
				
			},
			handleSearch1(){
				this.form.cityCode = ''
				this.form.shopName = ''
				this.form.regionId = ''
				this.form.regionId1 = ''
				this.form.provinceCode = ''
			},
			
			//获取门店列表
			getShopList(){
				let that = this;
				var loading = this.$loading({
					lock: true,
					text: 'Loading',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)'
				});
				that.markers = [];
				showShopSearch({
					cityCode:that.form.cityCode,//城市id
					firstRegionYy:that.form.regionId1,//运营大区id
					secondRegionYy:that.form.regionId,//运营小区id
					shopName:that.form.shopName,//门店名称
					// cityCode:that.form.cityCode,
					// shopName:that.form.shopName,
					// regionId:that.form.regionId,
				}).then(res=>{
					for(let i in res.data){
						if(res.data[i].shopStatus  ==0){
							res.data[i].icon ='https://msxr-1304081848.cos.ap-nanjing.myqcloud.com/20230323170534b83485318.png';
						}else if(res.data[i].shopStatus  ==1){
							res.data[i].icon ='https://msxr-1304081848.cos.ap-nanjing.myqcloud.com/202303231705381f2023310.png';
						}else{
							res.data[i].icon ='https://msxr-1304081848.cos.ap-nanjing.myqcloud.com/2023032317053012dad8278.png';
						}
						res.data[i].show = false;
						res.data[i].latitude =that.bd_encrypt(res.data[i].longitude,res.data[i].latitude).bd_lat;
						res.data[i].longitude =that.bd_encrypt(res.data[i].longitude,res.data[i].latitude).bd_lng;
						that.markers.push(res.data[i]);
						that.point = new BMap.Point(res.data[0].longitude,res.data[0].latitude);
						that.map.centerAndZoom(that.point, 16);
					}
					that.getShowShopStatistics()
					loading.close();
				})
			},
			
			
			
			//选择省份
			getProvince(e) {
				for (let i in this.pro_list) {
					if (this.pro_list[i].provinceCode == e) {
						this.city_list = this.pro_list[i].cityList;
						this.form.cityCode = this.pro_list[i].cityList[0].cityCode;
						this.getBigReg()
					}
				}
			},
			
			// 获取门店分布情况
			getShowShopStatistics(){
				let that = this;
				showShopStatistics({
					cityCode:that.form.cityCode,//城市id
					firstRegionYy:that.form.regionId1,//运营大区id
					secondRegionYy:that.form.regionId,//运营小区id
					shopName:that.form.shopName,//门店名称
				}).then(res=>{
					that.shop_sa = res.data;
					
				})
			},
			
			//高德地图经纬度转百度经纬度
			 bd_encrypt(gg_lng, gg_lat) {		  
			    var X_PI = Math.PI * 3000.0 / 180.0;
			    var x = gg_lng, y = gg_lat;
			    var z = Math.sqrt(x * x + y * y) + 0.00002 * Math.sin(y * X_PI);
			    var theta = Math.atan2(y, x) + 0.000003 * Math.cos(x * X_PI);
			    var bd_lng = z * Math.cos(theta) + 0.0065;
			    var bd_lat = z * Math.sin(theta) + 0.006;
			    return {
			        bd_lat: bd_lat,
			        bd_lng: bd_lng
			    };
					  
			},

			mapReady({
				BMap,
				map
			}) {
				// 选择一个经纬度作为中心点
				this.point = new BMap.Point(this.a,this.b);
				map.centerAndZoom(this.point, 16);
				this.map = map
			},
            
            //鼠标移入
			handleMover(e, index) {
				showShopSearch({shopId:e.shopId}).then(res=>{
					 this.shopInfo = res.data[0];
					this.markers[index].show = true
					
				})
			},

           //鼠标移出
			handleOut(e, index) {
				this.markers[index].show = false
			},



			// 点击查看详情
			lookDetail(marker, index) {
				let that = this;
				return false
				//this.map.clearOverlays();
				if (marker.type != 4) {
					var loading = this.$loading({
						lock: true,
						text: 'Loading',
						spinner: 'el-icon-loading',
						background: 'rgba(0, 0, 0, 0.7)'
					});
				}
				if (marker.type == 1) {
					setTimeout(function() {
						that.markers1 = [{
							type: '1',
							ap: 2,
							show: false,
							name: '门店1',
							longitude: '117.3',
							latitude: '31.87',
							icon: 'https://msxr-1304081848.cos.ap-nanjing.myqcloud.com/202303231705381f2023310.png'
						}]
					}, 2000)


					loading.close();
				} else if (marker.type == 2) {

					that.key_type = marker.type;
					that.show = true;
					loading.close();
				} else if (marker.type == 3) {

					that.key_type = marker.type;
					that.show = true;
					loading.close();
				} else {
					loading.close();

				}
			},
			

		},

		mounted() {
			this.getPri();
			this.getShowShopStatistics()
		}
	}
</script>

<style scoped>
	.tu_div{
		width: 100%;
		height: 150px;
		
	}
	.tu_div img{
		position: absolute;
		width: 110%;
		height: 150px;
		left: -16px;
		top: -17px;
		z-index: 1;
	}
	/* .map *{overflow: inherit !important; } */
	.shop_status{
		background-color: #3075D6;
		height: 24px;
		line-height: 24px;
		padding: 0 10px;
		border-radius: 0 0 12px 0px;
		text-align: center;
		color: #fff;
		position: absolute;
		left: -15px;
		top: -16px;
		z-index: 2;
	}
	.BMap_pop .BMap_center,.BMap_pop .BMap_top{
		border: none !important;
	}
	.address{
		font-size: 14px;
		color: #999999;
		max-width: 300px;
		padding-top: 10px;
	}
	.faren{
		font-size: 14px;
		color: #999999;
		padding-top: 10px;
		padding-bottom: 20px;
	}
	.lin{
		border-top: 1px solid #E9E9E9;
	}
	.info_top{
		position: relative;
		border-bottom: 1px solid #eee;
	}
	.info_top .titlse{
		text-align: center;
		color: #2A344A;
		font-size: 16px;
		font-weight: bold;
	}
	.yun{display: flex;align-items: center;padding:10px 0;}
	.yun img{width: 48px;height: 48px;border-radius: 50%;margin-right: 5px;}
	.BMapLabel {
		     border-color: rgba(0, 0, 0, 0) !important;
		    width: 120px;
		    white-space: nowrap !important;
		    overflow: hidden;
		    text-overflow: ellipsis;
		    text-align: center;
		    top: -21px !important;
		    left: -35px !important;
			height: 25px;
			line-height: 25px !important;
			background-color: #094693 !important;
			color: #fff;
			border-radius: 4px;
		  
	}
	
	.BMap_pop>div:nth-child(9){
		overflow: inherit !important;
	}
	.BMap_pop>div:nth-child(1)>div,.BMap_pop>div:nth-child(3)>div,.BMap_pop>div:nth-child(5)>div,.BMap_pop>div:nth-child(7)>div{
		border: none !important;
	}

	.map-detail {
		width: 100%;
		height: 100vh;
		position: fixed;
		z-index: 98;
		top: 0px;
	}

	.side_fixed {
		width: 300px;
		position: fixed;
		height: 90%;
		z-index: 99;
		left: 0px;
		top: 0px;
		background: #FFFFFF;
		box-shadow: 0px 3px 10px 1px rgba(0, 0, 0, 0.1);
		border-radius: 0px 25px 25px 0px;
		opacity: 1;
		box-sizing: border-box;
		padding: 20px 10px;
	}

	.top {
		display: flex;
		align-items: center;
		font-weight: bold;
		justify-content: center;
		font-size: 18px;
		color: #333333;
	}

	.top>div {
		font-weight: bold;
		margin-left: 5px;
	}

	.top span {
		color: #094693;
		font-weight: bold;
	}

	.shop_name {
		margin: 15px 10px 5px;
	}

	.city_select {
		margin: 0 10px;
		
		display: flex;
	}

	.h_line {
		border-top: 1px solid #E9E9E9;
		margin: 20px 0;
	}

	.bol_ti {
		color: #333333;
		font-size: 16px;
		font-weight: bold;
		padding: 0 10px;
	}

	.ul li {
		display: flex;
		padding: 0 10px;
		color: #999;
		justify-content: space-between;
		margin-top: 10px;
	}

	.ul li span {
		color: #333333;
		font-weight: bold;
	}

	.ul li i {
		width: 22px;
		margin-right: 10px;
		height: 12px;
		display: block;
		background: #357ADA;
		border-radius: 0px 0px 0px 0px;
		opacity: 1;
	}

	.flex {
		display: flex;
		align-items: center;
	}
</style>
